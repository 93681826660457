<template>
  <div :class="{ loader: true, fadeout: !isLoading }">
    <img src="../assets/marca/LNI.svg" alt="">
  </div>
</template>

<script>
export default {
  name: "LoadingScreen",
  props: ["isLoading"]

};
</script>

<style>
.loader {
  background-color: var(--bg-color);
  bottom: 0;
  color: var(--txt-color);
  display: block;
  font-size: 32px;
  left: 0;
  overflow: hidden;
  padding-top: 10vh;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 15000;
  top: 0;
}

.loader img {
    width: 10%;
    margin-top: 15%;
    animation: blink 4s forwards;
}

.fadeout {
  animation: fadeout 7s forwards;
}
@keyframes blink {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        opacity: 0;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes fadeout {
  0% {
        opacity: 1;
    }
    70% {
        opacity: 1;
    }
    90% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}
</style>