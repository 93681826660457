<template>
  <div class="main">
    <div class="item">
        <div class="item__photo">
            <img class="item__photo--img" src="../../assets/images/Home/portadaCuatro.jpg" alt="zaira">
        </div>        
        <div class="item__color">
            <img class="item__color--img" src="../../assets/textures/azul.png" alt="fondoRosa">
        </div>
        <div class="item__white"></div>
    </div>
  </div>
</template>

<script>
export default {
    Name: "PaintBack"
}
</script>

<style scoped>
.item{
  position: relative;
  display: flex;
}
.item__white{
  z-index: 2;
  background: var(--bk-color);
  width: 400px;
  height: 500px;
}
.item__color{
  z-index: 3;
  text-align: center;
  margin-right: -170px;
  margin-top: 50px;
  width: 400px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  vertical-align: middle;
}
.item__color--img{
  width: 100%;
  height: 100px;
  object-fit: cover;
}
.item__photo{
  z-index: 4;
  margin-right: -550px;
  margin-top: 100px;
  width: 400px;
  height: 400px;
}
.item__photo--img {
  width: 100%;
  height: 570px;
  object-fit: cover;
}
@media (max-width: 800px) {
    .item {
      display: flex;
      justify-content: flex-start;
    }
    .item__white {
      width: 300px;
      height: 400px;
    }
    .item__color {
     display: none;
    }
}
</style>