<template>
  <div class="main">
    <button :class="{change_color: scrollPosition > 100}">
      <slot name="text"></slot>
    </button>
  </div>
</template>

<script>
export default {
  name: "BtnNav",
  data() {
    return {
      scrollPosition: null
    }
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  destroy() {
    window.removeEventListener('scroll', this.updateScroll);
  }
};
</script>

<style scoped>
button {
  width: auto;
  font-family: var(--font);
  font-weight: 500;
  padding: 9px 0px;
  line-height: 28px;
  background-color: transparent;
  color: var(--bg-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.change_color {
  color: var(--txt-color);
  transition: color 0.5s linear;
}
button:hover {
  color: #f2f2f28f;
}
</style>