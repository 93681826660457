<template>
  <div class="home">
    <div class="main">
      <div class="main__background">
        <img id="eff" class="main__background--img" :src="imgDef" alt="paint" />
      </div>
      <div class="main__header">
        <h2 class="title">EL ARTE</h2>
        <h1 class="title">ES MI VOZ</h1>
      </div>
    </div>
    <div class="content">
      <div class="content__paint">
        <div class="content__paint--back">
          <paint-back></paint-back>
        </div>
        <div class="content__paint--inf">
          <div class="content__paint--title">MIS PINTURAS</div>
          <div class="content__paint--text">
            "Si pudieras decirlo con palabras, no habría razón para pintar"
            <p style="font-size: 15px">Edward Hopper</p>
          </div>
          <div class="content__paint--btn">
            <router-link to="/pinturas">
              <btn-prim>
                <template v-slot:text>
                  VER MÁS
                </template>
              </btn-prim>
            </router-link>
          </div>
        </div>
      </div>
      <div class="content__photo">
        <div class="content__photo--inf">
          <div class="content__photo--title">MIS FOTOGRAFÍAS</div>
          <div class="content__photo--text">
            “Lo importante es ver aquello que resulta invisible para los demás”
            <p style="font-size: 15px">Robert Frank</p>
          </div>
          <div class="content__photo--btn">
            <router-link to="/fotografias">
              <btn-prim>
                <template v-slot:text>
                  VER MÁS
                </template>
              </btn-prim>
            </router-link>            
          </div>
        </div>
        <div class="content__photo--back">
          <photo-back></photo-back>
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import PaintBack from '../components/Home/PaintBack.vue';
import BtnPrim from '../components/Buttons/BtnPrim.vue';
import Footer from '../components/Footer.vue';
import PhotoBack from '../components/Home/PhotoBack.vue';

/**FONDOS PRINCIPALES */
import P1 from '../assets/images/Home/portadaUno.jpg'
import P2 from '../assets/images/Home/portadaDos.jpg'
import P3 from '../assets/images/Home/portadaTres.jpg'
import P4 from '../assets/images/Home/portadaCuatro.jpg'
import P5 from '../assets/images/Home/portadaCinco.jpg'
import P6 from '../assets/images/Home/portadaSeis.jpg'

export default {
  data () {
    return {
      imgDef: P1,
      photos: {
        1: P1,
        2: P2,
        3: P3,
        4: P4,
        5: P5,
        6: P6
      }
    }
  },
  methods: {
    /*La función cambio() asigna un nuevo valor String obtenido 
    del Array definido en la data del script*/
    cambio(valor) {
      this.imgDef = this.photos[ valor ];
    },
    /*La función timer() acciona la función cambio() cada cierto tiempo
    asignado en el setTimeout()*/
    timer() {
      setTimeout(() => {
        this.cambio(1);
      }, 1000);
      setTimeout(() => {
        this.cambio(2);
      }, 3000);
      setTimeout(() => {
        this.cambio(3);
      }, 5000);
      setTimeout(() => {
        this.cambio(4);
      }, 7000);
      setTimeout(() => {
        this.cambio(5);
      }, 9000);
      setTimeout(() => {
        this.cambio(6);
      }, 11000);
    }
  },
  mounted: function() {
    /*La función setInterval() acciona la función timer() cada cierto tiempo,
    además de montarla al cargar la página*/
    setInterval(() => {
      this.timer();
    }, 10000);
  },
  components: { PaintBack, BtnPrim, Footer, PhotoBack },
  name: "Home"
};
</script>
<style scoped>
.home {
  position: relative;
}
.title {
  font-family: 'Yeseva One';
}
.main__header {
  width: 100%;
  position: absolute;
  color: var(--bg-color);
  text-align: center;
  top: 350px;
  margin: auto;
}
.main__header h1 {
  font-size: 80px;
  margin: 5px;
  line-height: 70px;
}
.main__header h2 {
  font-size: 40px;
  margin: 5px;
  line-height: 44px;
}
.main__background {
  position: relative;
}
.main__background--img {
  width: 100%;
  height: 870px;
  object-fit: cover;
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 3s;
  -moz-animation: fadeIn 3s;
  -o-animation: fadeIn 3s;
  -ms-animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
/** CONTENIDO DE PINTURAS */
.content__paint{
  display: flex;
  position: relative;
  padding: 200px 0px;
}
.content__paint--back{
  display: block;
  z-index: 1;
  position: relative;
}
.content__paint--inf{
  display: block;
  z-index: 2;
  position: relative;
  margin: 80px 0px 0px 100px;
}
.content__paint--title{
  font-family: 'Yeseva One';
  font-size: 40px;
}
.content__paint--text{
  width: 40%;
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 30px;
  font-style: italic;
  margin-top: 50px;
  margin-bottom: 50px;
}
/** CONTENIDO DE FOTOGRAFÍA */
.content__photo{
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding: 200px 0px;
  text-align: right;
}
.content__photo--back{
  display: block;
  z-index: 1;
  position: absolute;
}
.content__photo--back{
  display: block;
  z-index: 1;
  position: relative;
}
.content__photo--inf{
  display: block;
  z-index: 2;
  position: relative;
  margin: 80px 100px 0px 0px;
}
.content__photo--title{
  font-family: 'Yeseva One';
  font-size: 40px;
}
.content__photo--text{
  width: 40%;
  font-family: 'Montserrat';
  font-weight: 300;
  font-style: italic;
  font-size: 30px;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-inline-start: auto;
}
@media (max-width: 800px) {
    .content__paint{
      display: flex;
      position: relative;
      padding: 200px 0px;
      flex-direction: column;
    }
    .content__paint--inf {
      top: 200px;
      margin: 50px;
    }
    .content__paint--text {
      width: auto;
    }
    .content__photo {
      display: flex;
      position: relative;
      padding: 200px 0px;
      flex-direction: column-reverse;
    }
    .content__photo--inf {
      top: 200px;
      margin: 50px;
    }
    .content__photo--text {
      width: auto;
    }
}
</style>