<template>
  <div class="main">
    <button>
        <slot name="text"></slot>
    </button>
  </div>
</template>

<script>
export default {
    name: "BtnPrim"
}
</script>

<style scoped>
button {
    border: 1px solid;
    background: var(--bg-color);
    font-family: inherit;
    padding: 16px 36px;
}

button:hover{
    background: black;
    color: var(--bg-color);
    cursor: pointer;
    transition: all 0.5s;
}
</style>