import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  // GENERALES
  {
    path: "/about",
    name: "About",
    component: () =>
      import( "../views/About.vue"),
  },
  {
    path: "/blog",
    name: "Blog",
    component: () =>
      import( "../views/Blog/Blog.vue"),
  },
  {
    path: "/send",
    name: "Send",
    component: () =>
      import( "../views/Send.vue"),
  },
  {
    path: "/contacto",
    name: "Contacto",
    component: () =>
      import( "../views/Contacto/Contacto.vue"),
  },
  // FOTOGRAFÍA
  {
    path: "/fotografias",
    name: "Photos",
    component: () =>
      import( "../views/Fotografías/Photos.vue"),
  },
  // CATEGORÍAS DE FOTOGRAFÍAS
  {
    path: "/fotografias/corporativo",
    name: "Corporativo",
    component: () =>
      import( "../views/Fotografías/Corporativo.vue"),
  },
  {
    path: "/fotografias/familia",
    name: "Familia",
    component: () =>
      import( "../views/Fotografías/Familia.vue"),
  },
  {
    path: "/fotografias/gastronomia",
    name: "Gastronomía",
    component: () =>
      import( "../views/Fotografías/Gastronomía.vue"),
  },
  {
    path: "/fotografias/producto",
    name: "Producto",
    component: () =>
      import( "../views/Fotografías/Producto.vue"),
  },
  {
    path: "/fotografias/retrato",
    name: "Retrato",
    component: () =>
      import( "../views/Fotografías/Retrato.vue"),
  },
  // PINTURA
  {
    path: "/pinturas",
    name: "Paints",
    component: () =>
      import( "../views/Pinturas/Paints.vue"),
  },
  // TIENDA
  {
    path: "/store",
    name: "Tienda",
    component: () =>
      import( "../views/Tienda/Store.vue"),
  },
  // ELEMENTOS DE TIENDA
  {
    path: "/store/azulado",
    name: "Azulado",
    component: () =>
      import( "../views/Tienda/Azulado.vue"),
  },
  {
    path: "/store/barco",
    name: "Barco",
    component: () =>
      import( "../views/Tienda/Barco.vue"),
  },
  {
    path: "/store/calma",
    name: "Calma",
    component: () =>
      import( "../views/Tienda/Calma.vue"),
  },
  {
    path: "/store/cardiaco",
    name: "Cardiaco",
    component: () =>
      import( "../views/Tienda/Cardiaco.vue"),
  },
  {
    path: "/store/despierta",
    name: "Despierta",
    component: () =>
      import( "../views/Tienda/Despierta.vue"),
  },
  {
    path: "/store/desvanece",
    name: "Desvanece",
    component: () =>
      import( "../views/Tienda/Desvanece.vue"),
  },
  {
    path: "/store/detras",
    name: "Detras",
    component: () =>
      import( "../views/Tienda/Detras.vue"),
  },
  {
    path: "/store/encuentro",
    name: "Encuentro",
    component: () =>
      import( "../views/Tienda/Encuentro.vue"),
  },
  {
    path: "/store/mandala",
    name: "Mandala",
    component: () =>
      import( "../views/Tienda/Mandala.vue"),
  },
  {
    path: "/store/mar",
    name: "Mar",
    component: () =>
      import( "../views/Tienda/Mar.vue"),
  },
  {
    path: "/store/surrealista",
    name: "Surrealista",
    component: () =>
      import( "../views/Tienda/Surrealista.vue"),
  },
  {
    path: "/store/trompas",
    name: "Trompas",
    component: () =>
      import( "../views/Tienda/Trompas.vue"),
  },
  {
    path: "/store/universal",
    name: "Universal",
    component: () =>
      import( "../views/Tienda/Universal.vue"),
  },
  {
    path: "/store/viento",
    name: "Viento",
    component: () =>
      import( "../views/Tienda/Viento.vue"),
  },
  // PRIVACIDAD
  {
    path: "/privacidad",
    name: "Privacidad",
    component: () =>
      import( "../views/Privacidad.vue"),
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
