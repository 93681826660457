<template>
  <div class="main">
      <div class="footer__background">
          <div class="footer__background--section">
              <div class="section__menu">
                  <ul>
                      <router-link to="/about">
                        <li>ACERCA DE MÍ</li>
                      </router-link>
                      <router-link to="/pinturas">
                        <li>MIS PINTURAS</li>
                      </router-link>
                      <router-link to="/fotografias">
                        <li>MIS FOTOGRAFÍAS</li>
                      </router-link>
                  </ul>
              </div>
              <div class="section__btn">
                  <router-link to="/contacto">
                    <btn-prim>
                        <template v-slot:text>
                            CONTÁCTAME
                        </template>
                    </btn-prim>
                  </router-link>
              </div>
          </div>
          <div class="footer__background--brand">
              <img src="../assets/marca/LNI.svg" alt="ZairaBrand">
              <br>
              <p>EL ARTE ES MI VOZ</p>
              <br>
              <br>
              <p style="font-size: 10px; margin-top:2px;">2021</p>
              <router-link to="/privacidad" style="font-size: 10px; margin-top:2px;">Aviso de Privacidad</router-link>
          </div>
      </div>
  </div>
</template>

<script>
import BtnPrim from "./Buttons/BtnPrim.vue"
export default {
    Name: 'Footer',
    components: {BtnPrim}
}
</script>

<style scoped>
.main {
    position: relative;
}
.footer__background{
    background: var(--bk-color);
    padding: 50px;
    margin-top: 200px !important;
    margin: 50px;
}
.footer__background--section{
    display: flex;
    justify-content: space-between;
}
.footer__background--brand{
    width: 100%;
    text-align: center;
}
.footer__background--brand img{
    width: 5%;
    margin: auto;
}
a {
    text-decoration: none;
    color: inherit;
}
ul {
    padding: 0;
    margin: 0;
}
li {
    list-style: none;
    margin-bottom: 20px;
}
p {
    margin: 10px 0px;
    font-size: 15px;
    font-weight: 700;
}
@media (max-width: 800px) {
    .footer__background--section {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    li {
        text-align: center;
        font-size: 12px;
        margin-bottom: 30px;
    }
    .footer__background--brand img{
        margin-top: 50px;
        width: 25%;
    }
}
</style>