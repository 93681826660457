<template>
  <div id="nav">
    <LoadingScreen :isLoading="isLoading" />
    <div v-if="!isLoading">
      <Navbar ref="navbar"></Navbar>
      <router-view />
    </div>
  </div>
</template>
<script>
import Navbar from '@/components/Navbar.vue';
import LoadingScreen from './components/LoadingScreen.vue';

export default {
  data () {
    return {
      isLoading: true
    }
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
  components: {
    Navbar,
    LoadingScreen
  }
}
</script>

<style>
:root {
  --txt-color: #000000;
  --bg-color: #ffffff;
  --bk-color: #F8F8F8;
  --hv1-color: #fbae38;
  --hv2-color: #e24f1c;
  --hv3-color: #4fa3a5;
  --hv4-color: #f48192;
  --sh-color: #f8f8f8;
  --sh2-color: #e0e4e9;
  --font: "Montserrat", sans-serif;
  --img-sh: 20px 25px 56px -14px var(--sh-color);
  --card-sh: 10px 10px 20px var(--sh2-color), -10px -10px 20px var(--sh-color);
}
html,
body {
  margin: 0;
  background-color: var(--bg-color);
  font-family: var(--font) !important;
  scroll-behavior: smooth;
}

/******************************* SCROLL BAR ******************************/

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border: none;
}

::-webkit-scrollbar-track {
  background: var(--bg-color);
  border: none;
}

::-webkit-scrollbar-thumb {
  background: var(--hv-color);
  border: none;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--txt-color);
  border: none;
}
</style>
